.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.storedtail {
  position: relative;
  top: 0vh;
  width: auto;
  height: 30vh;
}

.storedtail1 {
  position: absolute;
  top: 0vh;
  width: auto;
  height: auto;
}

.storedtailsliderbg {
  position: absolute;
  text-align: center;

  display: block;
  text-align: center;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
}

.sdslidertitle {
  position: absolute;
  top: 65%;
  left: 5%;
  color: #ffffff;
  background: rgb(40, 40, 40, 0.6);
  border-radius: 5px;
}
.icon {
  float: left;
  text-align: center;
  height: 75;
  width: 75;
  border-radius: 75px;
}
.editbuttonr {
  position: fixed;
  bottom: 3%;
  right: 9%;
  z-index: 3;
}

.editbuttonl {
  position: fixed;
  bottom: 3%;
  right: 21%;
  z-index: 3;
}
.editbutton1r {
  position: fixed;
  bottom: 9%;
  right: 9%;
  z-index: 3;
}
.editbutton1l {
  position: fixed;
  bottom: 9%;
  right: 21%;
  z-index: 3;
}
.imagelist {
  border-top-left-radius: 4;
  border-top-right-radius: 4;
  border-bottom-left-radius: 4;
  border-bottom-right-radius: 4;
  background-size: cover;
  background-position: center;
}

.modalstyle {
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  background-color: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
}
.storeexpand {
  display: flex;
  justify-content: flex-end;
}
.qrcode {
  display: none;
}
.list1 {
  float: left;
}
.closebutton {
  position: absolute;
  top: 15%;
  right: 10%;
  z-index: 200;
  color: #ffffff;
  background: rgb(40, 40, 40, 0.6);
  border-radius: 5px;
}
.closebutton1 {
  position: absolute;
  top: 10%;
  right: 7%;
  z-index: 500;
  color: #ffffff;
  background: rgb(40, 40, 40, 0.6);
  border-radius: 5px;
}
.tags-input-container {
  border: 1px solid rgb(216, 216, 216);
  padding: 0.5em;
  border-radius: 3px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5em;
}

.tags-input {
  flex-grow: 1;
  padding: 0.5em 0;
  margin: 0.5em;
  border: none;
  outline: none;
}

.sokotype {
  position: relative;
  text-overflow: ellipsis;
  overflow: hidden;
}
.upperbar {
  position: fixed;

  color: #ffffff;
  z-index: 200;
}

.upperbar1 {
  position: relative;
  top: 5%;
  width: auto;
  z-index: 200;
  color: #ffffff;

  border-radius: 5px;
}
.pointer {
  cursor: pointer;
}

.underline {
  text-decoration: underline;
}

.selectmedia1 {
  width: 50%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.mediadelbutton {
  position: absolute;
  right: 5%;
  top: 10px;
  z-index: 10;
}

.dropzone {
  border: 1px solid rgb(216, 216, 216);
  padding: 0.5em;
  border-radius: 3px;
}
.mySwiper {
  width: 100%;
}
.backbutton {
  color: gray;
}
.scrollbutton {
  position: absolute;
  bottom: 5%;
  right: 5%;
  z-index: 200;
  color: #ffffff;
  background: rgb(25, 118, 210, 0.8);
  border-radius: 5px;
}
